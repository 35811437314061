import { S3Client, PutObjectCommand, DeleteObjectCommand } from '@aws-sdk/client-s3';
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from "buffer";
Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

const S3_ACCESS_KEY = process.env.REACT_APP_S3_ACCESS_KEY;
const S3_SECRET_KEY = process.env.REACT_APP_S3_SECRET_KEY;
const S3_REGION = process.env.REACT_APP_S3_REGION;
const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME;
const BASE_IMG_URL = process.env.REACT_APP_S3_BASE_IMG_URL;

// Configure AWS SDK
const s3Client = new S3Client({
    region: S3_REGION,
    credentials: {
        accessKeyId: S3_ACCESS_KEY,
        secretAccessKey: S3_SECRET_KEY
    }
});

export async function uploadMedia(file) {
    const id = uuidv4();
    const name = `${id}_${file.name}`;
    console.log(name)
    const uploadParams = {
        Bucket: S3_BUCKET_NAME,
        Key: `${name}`,
        Body: file,
        ContentType: file.type,
    };

    try {
        const command = new PutObjectCommand(uploadParams);
        await s3Client.send(command);
        const picUrl = `${BASE_IMG_URL}${name}`;
        return picUrl;
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
}

export async function deleteMedia(filename) {
    const name = filename.split('/').pop()
    console.log(name)
    const deleteParams = {
        Bucket: S3_BUCKET_NAME,
        Key: name
    };

    try {
        const command = new DeleteObjectCommand(deleteParams);
        await s3Client.send(command);
        return { success: true };
    } catch (error) {
        console.error('Error deleting file:', error);
        throw error;
    }
}
