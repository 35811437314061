import React from 'react'
import ActivityProfilesList from '../ActivityProfilesList'

export default function Interestreceive() {
    return (
        <ActivityProfilesList
            title={'Interests Receive'}
            url={'receiveinterest'}
        />
    )
}

