import React from 'react'
import Container from '../../components/Layouts/Container'

export default function SafetAndGuidelines() {
    return (
        <Container showFooter p={0}>

        </Container>
    )
}
